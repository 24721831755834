<div *ngIf="data">

    <div class="header">
        <div style="display: flex;">
            <div class="left">
                <div class="logo">
                    <img [src]="globals.selectedFormat.icon">
                </div>
                <div class="name">{{ globals.selectedReport.name }} - {{ globals.selectedFormat.name }}</div>
            </div>
            <div class="rigth">
                <div class="download" (click)="downLoadXlsx()" [class.hide]="globals.reportVersion !== 'client'"><i class="lni lni-cloud-download"></i></div>
                <div class="date">
                    <div class="title">Rango de fechas</div>
                    <input id="daterange" type="text" class="input" placeholder="AAAA - MM - DD // AAAA - MM - DD" name="daterangeInput" daterangepicker [options]="options" (selected)="selectedDate($event)"/>
                    <div class="dates">{{ data.general.startDate }} - {{ data.general.endDate }}</div>
                </div>
            </div>
        </div>
    
        <div class="row carders">
    
            <div class="col-lg col-sm-12" *ngIf="globals.reportVersion === 'client'">
                <div class="carder">
                    <div class="title">Presupuesto</div>
                    <div class="value">{{ data.general.symbol }}{{ data.general.budget | number }} <span class="currency">{{ data.general.currency }}</span></div>
                </div>
            </div>
    
            <div class="col-lg col-sm-12" *ngFor="let g of data.general.data">
                <div class="carder">
                    <div class="title text-truncate">{{ g.name }}</div>
                    <div class="value text-truncate" *ngIf="g.id !== 'cost' && g.id !== 'ctr' && g.id !== 'cpc' && g.id !== 'cpm' && g.id !== 'cpe' && g.id !== 'cpv' && g.id !== 'cpa'">{{ g.value | number }}</div>
                    <div class="value text-truncate" *ngIf="g.id === 'cost' || g.id === 'cpc' || g.id === 'cpm' || g.id === 'cpe' || g.id === 'cpv' || g.id === 'cpa'">{{ data.general.symbol }}{{ g.value | number }} <span class="currency">{{ data.general.currency }}</span></div>
                    <div class="value text-truncate" *ngIf="g.id === 'ctr'">{{ g.value }}%</div>
                </div>
            </div>
            
        </div>
    
    </div>
    
    <div class="row carders">
    
        <div class="col-lg-6 col-sm-12">
            <div class="cardGraph">
                <div class="title">Entrega por Día</div>
                <div class="graph" id="byDay"></div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="cardGraph">
                <div class="title">Entrega por día a la semana</div>
                <div class="graph" id="byWeek"></div>
            </div>
        </div>
    
        <div class="col-lg-12 col-sm-12">
            <div class="cardGraph">
                <div class="title">Entrega por Día</div>
                <table class="table">
                    <thead>
                      <tr>
                        <td class="head">Fecha</td>
                        <td class="head" *ngIf="globals.reportVersion === 'client'">Inversión</td>
                        <td class="head">Impresiones</td>
                        <td class="head">Clicks</td>
                        <td class="head" *ngIf="data.perDays[0].listens">Escuchas</td>
                        <td class="head">CTR</td>
                        <td class="head" *ngIf="globals.reportVersion === 'client'">CPM</td>
                        <td class="head" *ngIf="data.perDays[0].cpc && globals.reportVersion === 'client'">CPC</td>
                        <td class="head" *ngIf="data.perDays[0].cpe && globals.reportVersion === 'client'">CPE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let c of data.perDays">
                        <td>{{ c.day }}</td>
                        <td *ngIf="globals.reportVersion === 'client'">{{  data.general.symbol }}{{ c.cost | number }} {{ data.general.currency }}</td>
                        <td>{{ c.imps | number }}</td>
                        <td>{{ c.clicks | number }}</td>
                        <td *ngIf="c.listens">{{ c.listens | number }}</td>
                        <td>{{ c.ctr }}%</td>
                        <td *ngIf="globals.reportVersion === 'client'">{{  data.general.symbol }}{{ c.cpm | number }} {{ data.general.currency }}</td>
                        <td *ngIf="c.cpc && globals.reportVersion === 'client'">{{  data.general.symbol }}{{ c.cpc | number }} {{ data.general.currency }}</td>
                        <td *ngIf="c.cpe && globals.reportVersion === 'client'">{{  data.general.symbol }}{{ c.cpe | number }} {{ data.general.currency }}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
    
        <div class="col-lg-12 col-sm-12" *ngIf="data.genderAge.length > 0">
            <div class="cardGraph">
                <div class="title">Entrega por Edades y Genero</div>
                <div class="graph" id="byAgeGender"></div>
            </div>
        </div>
    
        <div class="col-lg-6 col-sm-12" *ngIf="data.creatives && data.creatives.length > 0">
            <div class="cardGraph">
                <div class="title">Entrega por Creativo</div>
                <table class="table">
                    <thead>
                      <tr>
                        <td class="head">Impresiones</td>
                        <td class="head">Clicks</td>
                        <td class="head" *ngIf="data.creatives[0].listens">Escuchas</td>
                        <td class="head">CTR</td>
                        <td class="head" *ngIf="globals.reportVersion === 'client'">CPM</td>
                        <td class="head" *ngIf="data.creatives[0].cpc && globals.reportVersion === 'client'">CPC</td>
                        <td class="head" *ngIf="data.creatives[0].cpe && globals.reportVersion === 'client'">CPE</td>
                        <td class="head"></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let c of data.creatives">
                        <td>{{ c.imps | number }}</td>
                        <td>{{ c.clicks | number }}</td>
                        <td *ngIf="c.listens">{{ c.listens | number }}</td>
                        <td>{{ c.ctr }}%</td>
                        <td *ngIf="globals.reportVersion === 'client'">${{ c.cpm | number }} {{ data.general.currency }}</td>
                        <td *ngIf="c.clicks && globals.reportVersion === 'client'">${{ c.cpc | number }} {{ data.general.currency }}</td>
                        <td *ngIf="c.cpe && globals.reportVersion === 'client'">${{ c.cpe | number }} {{ data.general.currency }}</td>
                        <td class="eye" (click)="viewCreative(c)"><i class="lni lni-eye"></i></td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12 text-center" *ngIf="data.creatives && data.creatives.length > 0">
            <div class="cardGraph">
                <img class="banner" [src]="selectedCreative" *ngIf="creativeType === 'image'">
                <audio [src]="selectedCreative" *ngIf="creativeType === 'audio'" controls></audio>
                <video [src]="selectedCreative" style="width: 100%;" controls *ngIf="creativeType === 'video'"></video>
                <div id="prevCreative" *ngIf="creativeType === 'tag'"></div>
            </div>
        </div>
    
        <div class="col-lg-6 col-sm-12" *ngIf="data.topGeo.length > 0">
            <div class="cardGraph">
                <div class="title">Top 10 por Región</div>
                <table class="table">
                    <thead>
                      <tr>
                        <td class="head">País</td>
                        <td class="head">Región</td>
                        <td class="head">Impresiones</td>
                        <td class="head text-center">Clicks</td>
                        <td class="head text-center" *ngIf="data.topGeo[0].listens">Escuchas</td>
                        <td class="head text-center">CTR</td>
                        <td class="head text-center" *ngIf="globals.reportVersion === 'client'">CPM</td>
                        <td class="head text-center" *ngIf="globals.reportVersion === 'client'">CPC</td>
                        <td class="head text-center" *ngIf="data.topGeo[0].cpe && globals.reportVersion === 'client'">CPE</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let g of data.topGeo">
                        <td>{{ g.country }}</td>
                        <td>{{ g.region }}</td>
                        <td>{{ g.imps | number }}</td>
                        <td class="text-center">{{ g.clicks | number }}</td>
                        <td class="text-center" *ngIf="g.listens">{{ g.listens | number }}</td>
                        <td class="text-center">{{ g.ctr }}%</td>
                        <td class="text-center" *ngIf="globals.reportVersion === 'client'">${{ g.cpm | number }} {{ data.general.currency }}</td>
                        <td class="text-center" *ngIf="globals.reportVersion === 'client'">${{ g.cpc | number }} {{ data.general.currency }}</td>
                        <td class="text-center" *ngIf="g.cpe && globals.reportVersion === 'client'">${{ g.cpe | number }} {{ data.general.currency }}</td>
                      </tr>
                    </tbody>
                  </table>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12" *ngIf="data.topGeo.length > 0">
            <div class="cardGraph">
                <div class="title">Entrega por Región</div>
                <div class="graph" id="byregion"></div>
            </div>
        </div>
    
        <div class="col-lg-6 col-sm-12" *ngIf="data.devices.length > 0">
            <div class="cardGraph">
                <div class="title">Entrega por dispositivo</div>
                <div class="graph" id="byDevices"></div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12" *ngIf="data.os.length > 0">
            <div class="cardGraph">
                <div class="title">Entrega por Sistema Operativo</div>
                <div class="graph" id="bySo"></div>
            </div>
        </div>
    
        <div class="col-lg-12 col-sm-12" *ngIf="data.prints.length > 0">
            <div class="cardGraph">
                <div class="title">Print</div>


                <div id="carouselPrints" class="carousel slide">
                    <div class="carousel-inner">
                      <div class="carousel-item active text-center" *ngFor="let p of data.prints">
                        <img class="print" [src]="p.file">
                      </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselPrints" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselPrints" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>

            </div>
        </div>
    
        <div class="col-lg-12 col-sm-12" *ngIf="data.comments !== ''">
            <div class="cardGraph">
                <div class="title">Comentarios</div>

                <div class="comment">{{ data.comments }}</div>

            </div>
        </div>
    
    </div>

</div>


<div class="copy"></div>