import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-warnertemplate01',
  templateUrl: './warnertemplate01.component.html',
  styleUrls: ['./warnertemplate01.component.scss']
})
export class Warnertemplate01Component implements OnInit {

  globals: any;
  colors = [];
  audio: any = new Audio('')
  currentTime: any = '00:00';
  duration: any = '00:00';
  progress: any = 0;
  c: any;
  loading: any = false;
  states: any = 1;

  constructor(globals: Globals) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.c = this.globals.campaign;
    this.colors =this.c.template.colors;
    this.loading = true;
    this.globals.play = false;
    setTimeout(() => {
      this.audio = new Audio(this.c.template.song);
      this.loading = false;
    }, 3000);
    let styles = document.createElement('style');
    styles.id = 'colorsData';
    document.querySelector('body').appendChild(styles);
    document.getElementById('colorsData').innerHTML = `
    body {
      --primary: #${this.colors[0]};
      --secondary: #${this.colors[1]};
      --relevant: #${this.colors[2]};
    }
    `;
  }

  playSong() {
    this.audio.id = 'audioSeg';
    if (!this.globals.play) {
      this.globals.play = true;
      this.audio.play();
      setInterval(() => {
        this.currentTime = Math.floor(this.audio.currentTime);
        let minutes: any = Math.round((this.audio.duration / 60));
        let seconds: any = Math.round(this.audio.duration / 2);
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        this.duration = `${minutes}:${seconds}`;
        this.progress = (this.currentTime / this.audio.duration) * 100;
        if (this.currentTime === this.duration) {
          this.audio.pause();
          this.audio.currentTime = 0;
        }
      }, 1000);
    } else {
      this.audio.pause();
      this.globals.play = false;
    }
  }

  playMet() {
    this.states = 2;
    this.playSong();
  }

}
